<template>
  <div class="wrap-table">
    <div class="table-body">
      <el-table
        :data="tableData"
        stripe
        border
        v-loading="isLoading"
        element-loading-text="数据加载中..."
      >
        <el-table-column label="标题">
          <template #default="scope">
            <el-button type="text" style="padding:0;color:#3c8dbc;min-height:0px;" @click="skipDetails(scope.row)">{{ scope.row.Title }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="CreateTime" label="发布时间" />
      </el-table>
    </div>
    <div class="table-footer2">
      <div class="footer-left2">
        {{ pagesize * (pageno - 1) + 1 }}-{{
          pageno * pagesize > total ? total : pageno * pagesize
        }}
        / {{ total }}
      </div>
      <el-pagination
        background
        @current-change="handlePageChange"
        layout="prev, pager, next, jumper"
        :total="total"
        :page-size="pagesize"
        :pager-count="5"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Ld: {
      type: String,
    },
  },
  data() {
    return {
      tableData: [],
      inputValue: "", //搜索的内容
      pageno: 1, //  当前页码
      pagesize: 5, // 一页显示5条数据
      isLoading: false, // 表格的loading效果
      total: 0, // 总数
    };
  },
  methods: {
    skipDetails(res){
      // console.log(res);
      let res2 = res.Id;
      // let rtype = this.type;
    
      this.$emit('rtitle',res.Title);//传递title值给父组件 便于给路由meta.title重新赋值
      this.$router.push({ path: "details", query: { Id: res2,type:3 } });
    },
    //模拟ajax请求 这里应当是通过type属性获取不同内容
    async getDataList(type) {
      console.log("父传子的：", type);
      this.isLoading = true;
      let res = await this.$ajax.post("/api/info/detail", {
        name: type,
        pageno: this.pageno,
        pagesize: this.pagesize,
      });
        // console.log(res);
      this.tableData = res.data.list;
      this.total = res.data.total;
      this.isLoading = false;
      // this.load()
    },
    // 分页
    handlePageChange(val) {
      // console.log(val);
      this.pageno = val;
      this.getDataList();
    },
  },
  mounted() {
    this.getDataList(this.Ld);
    //   console.log(this.tableData);
  },



};
</script>
<style scoped>
.table-footer2 {
  padding: 10px;
  overflow: hidden;
  border-right: 1px #ebeef5 solid;
  border-left: 1px #ebeef5 solid;
}
.footer-left2 {
  float: left;
  font-size: 13px;
  color: var(--el-text-color-regular);
}
.el-pagination {
  float: right;
}
.wrap-table {
  background: #ffffff;
}
.wrap-table:deep(.el-table thead) {
  color: #000000;
}
</style>>
    
