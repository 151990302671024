<template>
  <div class="wrap-info">
    <el-row :gutter="20">
      <el-col :md="6" :sm="24" :xs="24">
        <div class="wrap-mp">
          <div class="wp">
            <span>公开信息</span>
          </div>
          <el-menu default-active="1" class="el-menu-vertical-demo">
            <el-menu-item
              :index="item.Id"
              @click="getItem(item.Id)"
              v-for="(item, index) in titleList"
              :key="index"
            >
              <el-icon><Briefcase /></el-icon>
              <span>{{ item.label }}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>
      <el-col :md="18" :sm="24" :xs="24">
        <InfoList :Ld="ld" ref="son" @rtitle="getRouteTitle"></InfoList>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Briefcase } from "@element-plus/icons";
import InfoList from "./infoList";
export default {
data() {
    return {
      titleList: [
        { Id: "1", label: "通知公告", content: "" },
        { Id: "2", label: "表格下载", content: "" },
        { Id: "3", label: "项目公示", content: "" },
        { Id: "4", label: "政策指南", content: "" },
      ],
      ld: "1",//默认查询的值
      routeTitle: '',
    };
  },
  mounted() {
    // console.log(this.$refs.son);
  },
  methods: {
    getItem(type) {
      // console.log("点击测试", type);
      this.ld = type;
      this.$refs.son.getDataList(type);
      // this.$router.push({
      //       path:'/info/index',
      //       query:{type:type}
      // })
    },
    getRouteTitle(title){
      console.log('子传父的');
         this.routeTitle=title
    },
  },
    beforeRouteLeave(to) {
    // 在导航离开渲染该组件的对应路由时调用；可以访问组件实例 `this`
    if (to.path === "/info/details") {
      to.meta.title = this.routeTitle;
    }
  },
  components: {
    Briefcase,
    InfoList,
  },
  
};
</script>

<style scoped>

/* 、、、 */
.wp > span {
  font-size: 18px;
  padding-left: 5px;
  line-height: 40px;
}
.wrap-mp {
  /* border: 1px solid; */
  background: #ffffff;
  height: 650px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.wrap-mp .el-menu{
     border-right:0px;
}
.wrap-mp .el-menu-item.is-active{
border-left: 3px solid #3c8dbc;
font-weight: 800;
color: #000000;
}
.wrap-info .wrap-mp ul li:last-of-type{
   border-bottom: 1px solid #f4f4f4;
}
/* .wrap-info {
  border:1px solid red;
  padding-left: 10px;
  padding-right: 10px;
} */
</style>